import React, { useState, useEffect } from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import footer1 from '../../../../assets/regal_images/Android.png'
import footer2 from '../../../../assets/regal_images/ios.png'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { rtlModeAction, getRtlMode } from '../../../../store/mode/rtlmode'
import { ROUTES } from '../../../../constant/routes'
import playstore from '../../../../assets/regal_images/playstore.png'
import appstore from '../../../../assets/regal_images/appstore.png'
import {  useHistory } from 'react-router-dom'


const mapStateToProps = (state) => {
    return {
        rtlMode: getRtlMode(state)
    };


}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            rtlModeAction
        },
        dispatch
    )
})

const FooterStyle = (props) => {
    const [show, setShow] = useState(false);
    const history = useHistory()


    useEffect(() => {
        const rtlMode = sessionStorage.getItem('rtl-mode');
        if (rtlMode === null) {
            props.rtlModeAction(props.rtlMode)
        }
        else {
            props.rtlModeAction(rtlMode);
        }
    })

    return (
        <>
            <div className={`rtl-box ${show === true ? 'show' : ''}`} >

                <div className="rtl-panel">
                    <ul className="modes">
                        <li className="dir-btn" data-mode="rtl" data-active="false" onClick={() => { props.rtlModeAction('ltl') }} data-value="ltr"><Link to="#">LTR</Link></li>
                        <li className="dir-btn" data-mode="rtl" data-active="true" onClick={() => { props.rtlModeAction('rtl') }} data-value="rtl"><Link to="#">RTL</Link></li>
                    </ul>
                </div>
            </div>
            <footer id="contact" className="footer-one iq-bg-dark">
                <div className="footer-top">
                    <Container fluid>
                        <Row className="footer-standard">
                            <Col lg="12">
                                <div className="widget text-left">
                                    <div className="menu-footer-link-1-container">
                                        <ul id="menu-footer-link-1" className="menu p-0" style={{ zIndex: "999" }}>
                                            <li id="menu-item-7314" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7314">
                                                <Link to="/terms-of-service">Terms Of Use</Link>
                                            </li>
                                            <li id="menu-item-7316" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7316">
                                                <Link to="/privacy-policy">Privacy-Policy</Link>
                                            </li>
                                            <li id="menu-item-7316" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7316">
                                                <Link to="/refund-policy">Refund-Policy</Link>
                                            </li>
                                            {/* <li style={{cursor:"pointer"}} onClick={() => history.push(ROUTES.contactus) } id="menu-item-7316" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7316">
                                                <p>Contact Us</p>
                                            </li> */}

                                            <li id="menu-item-7118" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7118">
                                                <Link to="/about-us">About Us</Link>
                                            </li>
                                            {/* <li id="menu-item-7118" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7118">
                                                <Link to={ROUTES.watchlist}>Watch Later</Link>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                                <div className="widget text-left">
                                    <div className="">

                                        <p ><small>© 2024 24SevenFlix4u. 24SevenFlix4u is not the owner of all relevant images and content, and all movies and shows on this platform. It is uploaded only for the entertainment purpose. created and developed by <span style={{ color: "#ba000b" }}> 24SevenFlix4u.</span></small></p>

                                        {/* <div >
                                            <span>Available On</span>
                                            <div style={{ display: "flex" }}>
                                                <img style={{ height: "65px", width: "170px" }} src={playstore} alt="" />
                                                <img style={{ height: "65px", width: "170px" }} src={appstore} alt="" />
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </Col>
                            {/* <Col lg="2" md="6" className="mt-4 mt-lg-0">
                                <h6 className="footer-link-title">
                                    Follow Us :
                                </h6>
                                <ul className="info-share">
                                    <li><Link to="#" ><i className="fa fa-facebook"></i></Link></li>
                                    <li><Link to="#" ><i className="fa fa-twitter"></i></Link></li>
                                    <li><Link to="#" ><i className="fa fa-google-plus"></i></Link></li>
                                </ul>

                            </Col> */}
                            {/* <Col lg="3" md="6" className="mt-4 mt-lg-0">
                                <div className="widget text-left">
                                    <div className="textwidget">
                                        <h6 className="footer-link-title">Available on</h6>
                                        <div className="d-flex align-items-center">
                                            <Link className="app-image" to="#">
                                                <img src={footer1} alt="play-store" />
                                            </Link><br />
                                            <Link className="ml-3 app-image" to="#"><img src={footer2} alt="app-store" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </Col> */}
                        </Row>
                    </Container>
                </div>
            </footer>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterStyle)