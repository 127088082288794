import React, { useState, useEffect, useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import "./form.css"

import { useDispatch } from 'react-redux';
import { user_profile_list, profile_delete } from "../../../../actions/HomeActions"
import { useSelector } from 'react-redux';
import addProfile from "../../../../assets/images/addProfile.png"
import bg from "../../../../assets/images/Bg.jpg"
import { IMAGE_ENDPOINT } from '../../../../constant/ApiList';
import { PROFILE_MESSAGE } from '../../../../Actiontype/ActionType';
import { toast } from 'react-toastify';
import AlertMessage from '../../common/AlertMessage';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


const ProfileSelect = (props) => {
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const dispatch = useDispatch()
    let history = useHistory()
    const location = useLocation()
    const profiles = useSelector((state) => state.home.profiles);

    console.log(profiles, "profiles")
    useEffect(() => { dispatch(user_profile_list({ id: user_id })) }, [])


    const message = useSelector((state) => state.home.profile_message);

    useMemo(() => {
        if (message?.statuscode == 200 && message?.message != undefined) {
            toast.success(message?.message)

            dispatch({ type: PROFILE_MESSAGE, payload: null })
            dispatch(user_profile_list({ id: user_id }))
        }
    }, [message])

    const handleProfileDelete = (id) => {
        dispatch(profile_delete({ id: id }))
    }
    useMemo(() => {
        if (location?.state?.delete_profile_id)
            handleProfileDelete(location?.state?.delete_profile_id)
    }, [location?.state?.delete_profile_id])
    return (
        // <div style={{backgroundImage:"url("+bg+")",
        // backgroundSize:"cover"}}>
        <div>
            <AlertMessage />
            <div style={{
                display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", padding: "0 10%", flexDirection: "column",
                background: "rgb(0,0,0,.5)",

            }}>
                <h1 style={{ textAlign: "center", marginBottom: ".5rem", fontWeight: "unset", fontSize: "3.5vw", color: "white" }}>Manage Profiles</h1>
                <div className='res-profile-photo'>
                    {profiles?.data?.map((ele) => <div style={{ margin: "2rem", cursor: "pointer" }}   >
                        <div className='overlay-container'>
                            <img src={IMAGE_ENDPOINT + ele?.avatar?.avatarImage} style={{ maxWidth: "150px" }} className="rounded img-fluid d-block mx-auto mb-3" alt="user" />
                            <div class="overlay"><i className="fa fa-pencil" onClick={() => (
                                JSON.parse(ele?.profile_secure) ?
                                    history.push("/profile_pin", { profile: { ...ele, type: "profile_edit" } })
                                    : history.push("/profile_edit", { profile: { ...ele, profileImage: IMAGE_ENDPOINT + ele?.avatar?.avatarImage, avatar: ele?.avatar?.id } })


                            )}></i>{ele?.profileType == "user" && <i class="fa fa-trash" onClick={() =>
                                JSON.parse(ele?.profile_secure) ?
                                    history.push("/profile_pin", { profile: { ...ele, type: "profile_delete" } })
                                    : handleProfileDelete(ele?.id)} aria-hidden="true"></i>}</div>
                        </div>
                        <p style={{ textAlign: "center" }}>{ele?.profileName || "No Name"}</p>
                    </div>)}
                    {profiles?.data?.length < 4 && <div style={{ margin: "2rem", cursor: "pointer" }} >
                        <img src={addProfile} onClick={() => (history.push("/profile"))} style={{ maxWidth: "150px" }} className="rounded img-fluid d-block mx-auto mb-3" alt="user" />
                        <p style={{ textAlign: "center" }}>&nbsp;</p>
                    </div>}

                </div>
                <div className="btn btn-hover iq-button" onClick={() => history.push("/profile_selection")}>Done</div>
            </div>
        </div>
    )
}

export default ProfileSelect